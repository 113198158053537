import React from 'react'
import * as Modals from 'shared/modals'
import * as GlobalConstants from '../../constants'
import * as Constants from './constants'
import { Button } from 'reactstrap';
import { Loading } from "../loading";

export class ContactExportStartedModal extends Modals.ModalComponent {
  public static key: string = 'contactExportStart'
  constructor(props: Modals.ModalComponentProps) {
    super(props, ContactExportStartedModal.key)
  }
  protected renderChildren(data: any) {
    return 'We are exporting your contacts now. Check your inbox for a link to download the list.'
  }
}

export class ImportConsentModal extends Modals.ModalComponent {
  public static key: string = 'importConsent'
  public static defaultProps = {
    secondAction: Modals.ModalActionType.LINK,
  }
  constructor(props: Modals.ModalComponentProps) {
    super(props, ImportConsentModal.key)
  }
  protected renderChildren(data: any) {
    return (
      <div>
        <p>Do you have permission to email all contacts on this list?</p>
        { GlobalConstants.RASA_HELP_SUBSCRIBER_PERMISSION &&
          <a className="sub-text"
              href={GlobalConstants.RASA_HELP_SUBSCRIBER_PERMISSION}
              target="_blank"
              rel="noreferrer noopener">
            Click here to learn more about our permission guidelines.</a>
        }
      </div>
    )
  }

  protected close(data: any) {
    this.props.closeModal(ImportConsentModal.key, data)
    this.storeConsent(false)
  }

  protected save(data: any) {
    this.props.goTo(data.url)
    this.storeConsent(true)
  }

  private getConsentType = () => {
    return this.props.data[ImportConsentModal.key].data.url.includes('csv')
      ? 'ConsentCsvImport'
      : 'ConsentIntegrationsImport'
  }

  private storeConsent = (value) => {
    const consentType = this.getConsentType()
    if (consentType === Constants.CONSENT_CSV_IMPORT) {
      this.props.setSessionModal(ImportConsentModal.key, {
        consentType,
        consentValue: value,
      })
    } else {
      sessionStorage.setItem(consentType, value)
    }
  }

}

interface ResubscribeReasonState extends Modals.ModalComponentState {
  reason: string
}

interface ResubscribeReasonProps extends Modals.ModalComponentProps {
  isSaving: boolean
  onConfirm: (data: any, reason: string) => void
}

export class ResubscribeReasonModal extends Modals.GenericModalComponent<ResubscribeReasonProps, ResubscribeReasonState> {
  public static key: string = 'resubscribeReason'
  constructor(props: ResubscribeReasonProps) {
    super(props, ResubscribeReasonModal.key)
    this.state = {
      reason: ''
    }
  }

  protected renderChildren(data: any) {
    return this.props.isSaving ? <div className="loader-wrapper">
      <div className="balls">
        <Loading size="64" />
      </div>
    </div> :
      <div>
        <textarea
        className="form-control"
        rows={4}
        value={this.state.reason}
        onChange={(e) => this.setState({ reason: e.target.value })}
      />
    </div>
  }

  protected getFooterContent(data: any) {
    return <Button
      onClick={() => this.onConfirmClick(data)}
      disabled={!this.state.reason || this.props.isSaving}>
      Confirm
    </Button>
  }

  private onConfirmClick = (data) => {
    this.props.onConfirm(data, this.state.reason)
  }
}
