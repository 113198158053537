import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import { validateEmail } from 'generic/utility'
import { isEmpty } from 'lodash'
import * as React from 'react'
import { connect } from 'react-redux'
import * as Router from 'router'
import * as Modals from 'shared/modals'

interface EditContactState extends Modals.ModalComponentState {
  email: string,
  firstName: string,
  lastName: string,
  segmentCode?: string,
  segments?: any[],
}

interface EditContactProps extends Modals.ModalComponentProps {
  onSave: (payload: any) => any
}

export class EditContactModalComponent extends Modals.GenericModalComponent<EditContactProps,
  EditContactState> {
  public static contextType = RasaContext
  public static key: string = 'editContact'
  public static defaultProps = {
    closeButtonText: 'Close',
    saveText: 'Save',
    secondAction: Modals.ModalActionType.BUTTON,
    title: 'Edit Contact',
  }
  private isSaveButtonDisabled: boolean = false
  constructor(props: EditContactProps) {
    super(props, EditContactModalComponent.key, 'Edit Contact')
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      segmentCode: '',
      isSaving: false,
    }
  }

  public componentWillReceiveProps(nextProps) {
    const modalData = nextProps.data[this.key].data
    this.setState({
      email: modalData.email,
      firstName: modalData.first_name,
      lastName: modalData.last_name,
      segmentCode: modalData.segment_code
    })
  }

  protected renderChildren(data: any) {
    return (
      <div className="edit-contact-modal-body">
        {this.state.isSaving
          ? <Loading size="64" />
          : <div>
              <div className='input-wrapper'>
                <h5>E-mail:</h5>
                <input
                  className='form-control input-field'
                  type="text"
                  value={this.state.email}
                  placeholder="Enter email..."
                  onChange={(e) => {
                    e.preventDefault()
                    this.setState({ email: e.target.value })
                  }}
                />
              </div>
              <div className='input-wrapper'>
                <h5>First Name:</h5>
                <input
                  className='form-control input-field'
                  type="text"
                  value={this.state.firstName}
                  placeholder="Enter first name..."
                  onChange={(e) => {
                    e.preventDefault()
                    this.setState({ firstName: e.target.value })
                  }}
                />
              </div>
              <div className='input-wrapper'>
                <h5>Last Name:</h5>
                <input
                  className='form-control input-field'
                  type="text"
                  value={this.state.lastName}
                  placeholder="Enter last name..."
                  onChange={(e) => {
                    e.preventDefault()
                    this.setState({ lastName: e.target.value })
                  }}
                />
              </div>
              {data.hasSegmentAccess && !data.isImportedSegment &&
                <div className="input-wrapper">
                  <h5>Segment Code:</h5>
                  <LoadSegmentCodes
                    segmentCode={this.state.segmentCode}
                    setSegmentCode={this.setSegmentCode}
                    setConfigData={this.setSegments}
                    hideLabel={true}></LoadSegmentCodes>
                </div>
              }
            </div>
        }
      </div>
    )
  }

  protected onModalClose(data: any): void {
    this.setState({
      isSaving: false
    })
    this.props.closeModal(EditContactModalComponent.key)
  }

  protected close = (data: any) => {
    this.setState({ isSaving: false })
    return this.props.closeModal(EditContactModalComponent.key)
  }

  protected saveDisabled(data: any): boolean {
    return isEmpty(this.state.firstName) || isEmpty(this.state.lastName) || !validateEmail(this.state.email) ? true : this.isSaveButtonDisabled
  }

  protected doSave(data: any) {
    this.setState({ isSaving: true })

    return this.props.onSave({
      email: this.state.email,
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      segmentCode: this.state.segmentCode
    })
      .then(() => {
        this.setState({ isSaving: false })
      })
      .catch(() => this.setState({ isSaving: false }))
  }

  private setSegmentCode = (code: any) => {
    this.setState({
      segmentCode: code.key,
    })
  }

  private setSegments = (segments: any[]) => {
    this.setState({
      segments,
    })
  }
}

export const EditContactModal = connect(
  null,
  {
    push: Router.push,
  },
)(EditContactModalComponent)
