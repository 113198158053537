import { DatePicker } from '@progress/kendo-react-dateinputs'
import { GridColumn as Column } from '@progress/kendo-react-grid'
import * as Sentry from '@sentry/browser'
import { canAccessSegment } from 'app/utils'
import { ClickOutside } from 'components/click-outside/component'
import { LoadSegmentCodes } from 'components/common/load-partner-codes/component'
import { DateTimeCell } from 'components/content-pool/kendocells'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { DropdownComponent, DropdownOption } from 'components/dropdown/component'
import * as Flash from 'components/flash'
import { HeaderComponent } from 'components/header/component'
import { CarrotDown } from 'components/icons/carrot-down'
import { CarrotUp } from 'components/icons/carrot-up'
import { SearchIcon } from 'components/icons/searchicon'
import { MenuItem, TabMenu } from 'components/tab-menu/component'
import { RasaContext } from 'context'
import { format } from 'date-fns'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { DatasetParam } from 'generic/dataset'
import { EditableDropdownCell, EditCellProps } from 'generic/editCell'
import * as GenericRedux from 'generic/genericRedux'
import { RasaBrowserComponent } from 'generic/rasaBrowserComponent'
import { RasaDataGrid } from 'generic/rasaDataGrid'
import { RasaReactComponent } from 'generic/rasaReactComponent'
import * as GenericUtils from 'generic/utility'
import { updateContactStatus } from 'generic/utility'
import { debounce, isEmpty } from 'lodash'
import pluralize from 'pluralize'
import * as Query from 'query-string'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap'
import { formatDateForES } from 'shared_server_client/dates'
import { BillingPlanDetailCode, isOverContactLimit } from 'shared_server_client/types/billing_plan'
import { enterpriseUsage, UsageStats } from 'shared_server_client/types/usage_stats'
import * as Constants from './constants'
import {ContactExportStartedModal, ResubscribeReasonModal} from './modals'
import './styles.css'
import { UNSUBSCRIBE_REASON } from '../../shared_server_client/constants'
import { ERROR_STATUS, MESSAGE } from './constants'
import { Loading } from '../loading';
import { STATUS_CODES } from '../../constants';

interface SubscriberCounts {
  subscribed: number,
  unsubscribed: number,
  total: number,
}

interface SubscribersState {
  addSuccess: boolean
  addUserModal: boolean,
  addError: boolean,
  communityId: string,
  drop: boolean,
  generatingCSV: boolean,
  searchedMembers: any[],
  usageStats: UsageStats,
}

const SUBSCRIBED_FILTER_OPTIONS: DropdownOption[] = [
  { key: Constants.ContactFilter.SELECT, value: Constants.ContactFilter.SELECT,
    description: 'Select' },
  { key: Constants.ContactFilter.MODIFIED_SINCE, value: Constants.ContactFilter.MODIFIED_SINCE,
    description: 'Modified Since' },
  { key: Constants.ContactFilter.NEVER_CLICKED, value: Constants.ContactFilter.NEVER_CLICKED,
    description: 'Never Clicked' },
  { key: Constants.ContactFilter.NEVER_OPENED, value: Constants.ContactFilter.NEVER_OPENED,
    description: 'Never Opened' },
  { key: Constants.ContactFilter.NOT_CLICKED_SINCE, value: Constants.ContactFilter.NOT_CLICKED_SINCE,
    description: 'Not Clicked Since' },
  { key: Constants.ContactFilter.NOT_OPENED_SINCE, value: Constants.ContactFilter.NOT_OPENED_SINCE,
    description: 'Not Opened Since' },
]

const UNSUBSCRIBED_FILTER_OPTIONS: DropdownOption[] = [
  { key: Constants.ContactFilter.SELECT, value: Constants.ContactFilter.SELECT, description: 'Select' },
  { key: Constants.ContactFilter.UNSUBSCRIBED_BEFORE, value: Constants.ContactFilter.UNSUBSCRIBED_BEFORE, description: 'Unsubscribed Before' },
  { key: Constants.ContactFilter.UNSUBSCRIBED_REASON, value: Constants.ContactFilter.UNSUBSCRIBED_REASON, description: 'Unsubscribed Reason' },
  { key: Constants.ContactFilter.UNSUBSCRIBED_SINCE, value: Constants.ContactFilter.UNSUBSCRIBED_SINCE, description: 'Unsubscribed Since' },
]

const UnSubscribedReasonsFilterOptions: DropdownOption[] = [
  { key: Constants.ContactFilter.SELECT, value: Constants.ContactFilter.SELECT, description: 'Select' },
  ...Object.values(UNSUBSCRIBE_REASON).map(value => ({
    key: value,
    value,
    description: value.replace(/(?<=[a-z])(?=[A-Z])/g, ' '),
  })),
]

type SubscriberCountsProps = GenericRedux.AllComponentPropsWithModal<SubscriberCounts>

class SubscriberCountsComponent extends RasaReactComponent<SubscriberCountsProps, SubscribersState> {
  public static contextType = RasaContext
  constructor(props: SubscriberCountsProps) {
    super(props, 'communitySubscriberCounts')

    this.state = {
      addUserModal: false,
      addSuccess: false,
      addError: false,
      generatingCSV: false,
      isDirty: false,
      isSaving: false,
      isLoading: false,
      communityId: null,
      drop: false,
      searchedMembers: [],
      usageStats: enterpriseUsage,
    }

    this.addContact = this.addContact.bind(this)
    this.toggleNotification = this.toggleNotification.bind(this)
  }

  public componentDidMount = () => {
    this.context.user.init().then(({ person, activeCommunity }) => {
      this.loadRecord(activeCommunity.communityId, activeCommunity.communityId)
      this.setState({
        communityId: activeCommunity.communityId,
        usageStats: activeCommunity.billingInfo.usageStats,
      })
    })
  }

  public render() {
    const data = this.props.data
    const description = `You have ${pluralize('contact', (data.total || 0), true)}`
    return <div className="all-contacts-page">
      <div className="header-container">
        <HeaderComponent
          title={'CONTACTS'}
          subTitle={'Your Contacts'}
          description={[description]}
        />
        <div className="actions-container">
          <ClickOutside
            handleClickOutside={() => this.state.drop ? this.setState({ drop: !this.state.drop }) : null}>
            <div className="dropdown-container">
              <Button onClick={() => this.setState({ drop: !this.state.drop })} className="dropdown-button">
                Actions &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                {this.state.drop ? <CarrotUp className="carrot" /> : <CarrotDown className="carrot" />}
              </Button>
              {this.state.drop ?
                <div className="dropdown-content dropdown-content-contacts">
                  {!isOverContactLimit(this.state.usageStats) &&
                    <a className="button1" onClick={this.addContact}
                      href="#">
                      Add a Contact
                    </a>}
                  <a className="button1"
                    onClick={() => this.props.push(`${DashboardMenuOption.contacts}/add-contacts`)}
                    href="#">
                    Import
                  </a>
                  <a className="button1" onClick={() => this.exportContacts(false)}
                    href="#">
                    Export All
                  </a>
                  <a className="button1" onClick={() => this.exportContacts(true)}
                    href="#">
                    Export Selected View
                  </a>
                </div>
                : null}
            </div>
          </ClickOutside>
          <div className="modal-wrapper">
            <ContactExportStartedModal data={this.props.modals} closeModal={this.props.closeModal} title="Export Started" />
          </div>
        </div>
      </div>
    </div>
  }

  private addContact = () => this.props.push('/contacts/add-contact')

  private getSearchParams = (filteredContacts: boolean = false) => {
    const queryParams = Query.parse(location.search)
    const params = []

    if (filteredContacts) {
      params.push([Constants.STATUS_PARAM, queryParams.status || Constants.ACTIVE_STATUS])
      params.push(['searchTerm', queryParams.searchTerm && queryParams.searchTerm.length >= 3 ?
      queryParams.searchTerm : '' ])
      switch (queryParams.filterValue) {
        case Constants.ContactFilter.NOT_OPENED_SINCE:
          params.push(['hasNotOpenedSince', this.getDateFilter(queryParams.date)])
          break
        case Constants.ContactFilter.NOT_CLICKED_SINCE:
          params.push(['hasNotClickedSince', this.getDateFilter(queryParams.date)])
          break
        case Constants.ContactFilter.NEVER_CLICKED:
          params.push(['hasNeverClicked', '1'])
          break
        case Constants.ContactFilter.NEVER_OPENED:
          params.push(['hasNeverOpened', '1'])
          break
        case Constants.ContactFilter.MODIFIED_SINCE:
          params.push(['modifiedSince', this.getDateFilter(queryParams.date)])
          break
        case Constants.ContactFilter.UNSUBSCRIBED_BEFORE:
          params.push(['unsubscribedBefore', this.getDateFilter(queryParams.date)])
          break
        case Constants.ContactFilter.UNSUBSCRIBED_SINCE:
          params.push(['unsubscribedSince', this.getDateFilter(queryParams.date)])
          break
        case Constants.ContactFilter.UNSUBSCRIBED_REASON:
          params.push(['unsubscribedReason', queryParams.unsubscribedReason])
          break
        case Constants.ContactFilter.SEGMENT_CODE:
          params.push(['segmentCode', queryParams.segmentCode])
          break
      }
    }
    return params
  }

  private getDateFilter(date: any) {
    return date ? formatDateForES(new Date(date)) : ''
  }

  private exportContacts = (filteredContacts: boolean = false) => {
    this.setState({ generatingCSV: true }, () => {
      const url: URL = new URL(`/${this.state.communityId}/subscribers/export`, AjaxWrapper.getServerUrl())
      url.search = new URLSearchParams(this.getSearchParams(filteredContacts)).toString()
      return AjaxWrapper.ajax(url.href, HttpMethod.GET, null)
        .then((response) => {
          const { status, data } = response

          if (status === 'done') {
            return GenericUtils.downloadTextFile(data, 'csv')
          } else if (status === 'pending') {
            return this.props.openModal(ContactExportStartedModal.key, {})
          }

          throw new Error(`Unknown response from ${url.href}`)
        })
        .then(() => this.setState({ generatingCSV: false }))
        .catch((err) => {
          this.setState({ generatingCSV: false })
          this.context.store.dispatch(Flash.showFlashError(Constants.EXPORT_CSV_ERROR))
          Sentry.captureException(err)
        })
    })
  }

  private toggleNotification = () => {
    this.setState({ addSuccess: !this.state.addSuccess })
    if (!this.state.addSuccess) {
      setTimeout(() => {
        this.setState({ addSuccess: false });
      }, 2000)
    }
  }
}

const initialCountsState: SubscriberCounts = {
  subscribed: 0,
  unsubscribed: 0,
  total: 0,
}

const SubscriberCounts = GenericRedux.registerNewComponentWithModals<SubscriberCounts>(
  SubscriberCountsComponent,
  'subscriber_counts',
  [ContactExportStartedModal.key],
  initialCountsState,
)
type Subscriber = any
// TODO: set these types
interface SubscribersMainProps extends GenericRedux.DatasetContainerComponentProps<Subscriber> {
  imageWidth?: number,
  subscriberCounts: SubscriberCounts,
  onSearch?: any,
}

type SubscribersProps = SubscribersMainProps & GenericRedux.AllComponentPropsWithModal<any>

interface SubscribersGridState {
  deleteUserModal: boolean,
  searchedMembers: any[],
  communityId: string,
  hasSegmentAccess: boolean,
  searchInput: string,
  searchTerm: string,
  selectedSection: string,
  date?: any,
  selectedUnSubscribedReason: DropdownOption,
  filterValue?: string,
  unsubscribedReason?: string,
  filterApplied?: boolean,
  segmentCode?: string,
}

const NonDateFilters = [
  Constants.ContactFilter.SELECT,
  Constants.ContactFilter.NEVER_CLICKED,
  Constants.ContactFilter.NEVER_OPENED,
  Constants.ContactFilter.SEGMENT_CODE,
  Constants.ContactFilter.UNSUBSCRIBED_REASON,
]

const dateFormat = 'MM/dd/yyyy'

export class SubscribersHomePageComponent extends RasaBrowserComponent<SubscribersProps, SubscribersGridState> {
  public static contextType = RasaContext
  private subscribedFilterOptions: DropdownOption[] = SUBSCRIBED_FILTER_OPTIONS
  private unSubscribedFilterOptions: DropdownOption[] = UNSUBSCRIBED_FILTER_OPTIONS

  private setSearch = debounce((search) => {
    this.setQuery(Constants.ContactQueryFilters.SEARCH_TERM, search)
  }, 500)

  constructor(props: SubscribersProps, datasetParams: DatasetParam[]) {
    super(props, {
      deleteUserModal: false,
      hasSegmentAccess: false,
      searchedMembers: [],
      communityId: null,
      searchInput: '',
      searchTerm: null,
      selectedSection: Constants.ContactSections.subscribed,
      date: null,
      selectedUnSubscribedReason: UnSubscribedReasonsFilterOptions[0],
      filterValue: null,
      unsubscribedReason: null,
      filterApplied: false,
      segmentCode: null,
    })
  }

  public componentDidMount = () => {
    this.context.user.init().then(({ person, activeCommunity }) => {
      this.subscribedFilterOptions = SUBSCRIBED_FILTER_OPTIONS
      this.unSubscribedFilterOptions = UNSUBSCRIBED_FILTER_OPTIONS
      const avlFeatures: BillingPlanDetailCode[] = activeCommunity.billingInfo.currentPlan.features || []
      this.setState({
        communityId: activeCommunity.communityId,
      })
      canAccessSegment(avlFeatures, activeCommunity.communityId).then((canAccess) => {
        this.setState({hasSegmentAccess: canAccess})
        if (canAccess) {
          if (this.subscribedFilterOptions.findIndex((x) => x.key === Constants.ContactFilter.SEGMENT_CODE) === -1) {
            this.subscribedFilterOptions.push({key: Constants.ContactFilter.SEGMENT_CODE, value: Constants.ContactFilter.SEGMENT_CODE, description: 'Segment Code' })
          }
        } else {
          if (this.subscribedFilterOptions.findIndex((x) => x.key === Constants.ContactFilter.SEGMENT_CODE) > -1) {
            this.subscribedFilterOptions.splice(this.subscribedFilterOptions.findIndex((x) => x.key === Constants.ContactFilter.SEGMENT_CODE), 1)
          }
        }
      })
    })
    this.loadStateFromUrl()
  }

  public renderSearch() {
    return (
      <div className="filter-wrapper">
        <div className="search-bar">
          <label>
            <SearchIcon/>
            <input type="text"
              value={this.state.searchInput}
              placeholder="Search by firstname, lastname or email"
              onChange={(e) => {
                this.setState({
                  searchInput: e.target.value,
                })
                this.setSearch(e.target.value)
              }} />
          </label>
        </div>
        <div className="filter-section">
        <div className="filter-icon-container">
          <i className="fa fa-filter" aria-hidden="true" />
        </div>
        <DropdownComponent
          data={(this.state.selectedSection === Constants.ContactSections.subscribed)
            ? this.subscribedFilterOptions
            : this.unSubscribedFilterOptions
          }
          selected={this.state.filterValue}
          onChange={this.onDropdownChange}
        />
        {this.showSinceDate() && <div>
          <span className="filter-text">Since</span>
          <DatePicker className="schedule-date-picker"
            format="yyyy-MM-dd"
            value={this.state.date ? new Date(this.state.date ) : new Date()}
            onChange={this.onDateChange} max={new Date()} >
          </DatePicker>
        </div>
        }
        {this.showUnsubscribedReason() && <div className="unsubscribed-reason-container">
          <span className="filter-text">Reason</span>
          <DropdownComponent
            data={UnSubscribedReasonsFilterOptions}
            selected={this.state.selectedUnSubscribedReason.key}
            onChange={this.onUnsubscribedReasonDropdownChange}
          />
        </div>
        }
        {this.showSegmentCode() &&
          <><LoadSegmentCodes setSegmentCode={this.setSegmentCode} hideLabel={true}
            segmentCode={this.state.segmentCode}/></>
        }
      </div>
      </div>
    )
  }

  public render() {
    return (
      <div className="all-contacts">
        <SubscriberCounts />
        {this.renderSearch()}
        <div>
          <div className="grid-table grid-table-contacts">
            <TabMenu menus={this.contactMenus()}
              selectedItemProps={this.getSearchParams()}
              onSearch={this.onSearch}
              selectedItemKey={this.state.selectedSection}
              onSelect={(item: MenuItem) => {
                if (this.state.selectedSection !== item.key) {
                  this.setState({ selectedSection: item.key }, () => this.onTabSelect())
                }
              }} />
          </div>
        </div>
        <Modal isOpen={this.state.deleteUserModal}>
          <ModalBody className="delete-modal">
            <div>
              <h4>Are You Sure?</h4>
              <p>Are you sure you want to delete this contact forever?</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button>
              Yes, delete forever.
            </Button>
            <p>Cancel </p>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  private getSearchParams() {
    const params = [{ param: 'searchTerm', value: this.state.searchTerm && this.state.searchTerm.length >= 3 ?
        this.state.searchTerm : '' }]
    switch (this.state.filterValue) {
      case Constants.ContactFilter.NOT_OPENED_SINCE:
        params.push({ param: 'hasNotOpenedSince', value: this.getDateFilter() })
        break
      case Constants.ContactFilter.NOT_CLICKED_SINCE:
        params.push({ param: 'hasNotClickedSince', value: this.getDateFilter() })
        break
      case Constants.ContactFilter.NEVER_CLICKED:
        params.push({ param: 'hasNeverClicked', value: '1' })
        break
      case Constants.ContactFilter.NEVER_OPENED:
        params.push({ param: 'hasNeverOpened', value: '1' })
        break
      case Constants.ContactFilter.MODIFIED_SINCE:
        params.push({ param: 'modifiedSince', value: this.getDateFilter() })
        break
      case Constants.ContactFilter.UNSUBSCRIBED_BEFORE:
        params.push({ param: 'unsubscribedBefore', value: this.getDateFilter() })
        break
      case Constants.ContactFilter.UNSUBSCRIBED_SINCE:
        params.push({ param: 'unsubscribedSince', value: this.getDateFilter() })
        break
      case Constants.ContactFilter.UNSUBSCRIBED_REASON:
        params.push({ param: 'unsubscribedReason', value: this.state.unsubscribedReason })
        break
      case Constants.ContactFilter.SEGMENT_CODE:
        params.push({ param: 'segmentCode', value: this.state.segmentCode })
        break
    }
    return params
  }

  private getDateFilter() {
    return this.state.date ? formatDateForES(new Date(this.state.date)) : ''
  }

  private showSinceDate() {
    if (this.state.selectedSection === Constants.ContactSections.subscribed) {
      if (this.state.filterValue === Constants.ContactFilter.SELECT
        || isEmpty(this.state.filterValue)
        || this.state.filterValue === Constants.ContactFilter.NEVER_CLICKED
        || this.state.filterValue === Constants.ContactFilter.NEVER_OPENED
        || this.state.filterValue === Constants.ContactFilter.SEGMENT_CODE) {
        return false
      }
      return true
    } else if (this.state.selectedSection === Constants.ContactSections.unsubscribed) {
      if (this.state.filterValue === Constants.ContactFilter.SELECT
        || isEmpty(this.state.filterValue)
        || this.state.filterValue === Constants.ContactFilter.UNSUBSCRIBED_REASON) {
        return false
      }
      return true
    }
    return false
  }

  private showUnsubscribedReason() {
    if (this.state.selectedSection === Constants.ContactSections.unsubscribed) {
      if (this.state.filterValue === Constants.ContactFilter.UNSUBSCRIBED_REASON) {
        return true
      }
    }
    return false
  }

  private showSegmentCode() {
    if (this.state.selectedSection === Constants.ContactSections.subscribed) {
      if (this.state.filterValue === Constants.ContactFilter.SEGMENT_CODE) {
        return true
      }
    }
    return false
  }

  private setSegmentCode = (code: any) => {
    this.setQuery(Constants.ContactQueryFilters.SEGMENT_CODE, code ? code.value : '')
  }

  private contactMenus = (): MenuItem[] => {
    const { subscriberCounts } = this.props
    return [
      {
        key: Constants.ContactSections.subscribed,
        name: `Subscribed (${subscriberCounts.subscribed})`,
        component: SubscribedSubscribersGrid,
      },
      {
        key: Constants.ContactSections.unsubscribed,
        name: `Unsubscribed (${subscriberCounts.unsubscribed})`,
        component: UnsubscribedSubscribersGrid,
      },
    ]
  }

  private onSearch = (counts: any) => {
    const { subscriberCounts } = this.props
    if (counts) {
      if (counts.subscribed || counts.subscribed === 0) {
        subscriberCounts.subscribed = counts.subscribed
      }

      if (counts.unsubscribed || counts.unsubscribed === 0) {
        subscriberCounts.unsubscribed = counts.unsubscribed
      }
      this.setState({
        filterApplied: true,
      })
    }
  }

  private onTabSelect = () => {
    // clear filter state
    this.clearQueryParams()
    this.resetFilterState()
    this.setQuery(Constants.ContactQueryFilters.STATUS,
      this.state.selectedSection === Constants.ContactSections.unsubscribed ?
      Constants.INACTIVE_STATUS : Constants.ACTIVE_STATUS)
    this.setHash(this.state.selectedSection)
    // clear dropdowns
    this.setState({
      searchTerm: null,
      searchInput: '',
      selectedUnSubscribedReason: UnSubscribedReasonsFilterOptions[0],
      filterValue: null,
    })
  }

  private resetFilterState = () => {
    this.setState({
      date: null,
      unsubscribedReason: null,
      segmentCode: null,
    })
  }

  private onDropdownChange = (e) => {
    if (e.selected.key) {
      //clear the existing selected filter.
      this.clearQueryParams()
      this.resetFilterState()

      this.setQuery(Constants.ContactQueryFilters.FILTER_VALUE, e.selected.key).then(() => {
        if (NonDateFilters.includes(e.selected.key)) {
          this.setQuery(Constants.ContactQueryFilters.DATE, '')
        }
      })
    }
  }

  private onDateChange = (e) => {
    if (!e.value) {
      return
    }
    switch (this.state.filterValue) {
      case 'NotOpenedSince':
        this.setQuery(Constants.ContactQueryFilters.DATE, format(e.value, dateFormat))
        break
      case 'NotClickedSince':
        this.setQuery(Constants.ContactQueryFilters.DATE, format(e.value, dateFormat))
        break
      case 'ModifiedSince':
        this.setQuery(Constants.ContactQueryFilters.DATE, format(e.value, dateFormat))
        break
      case 'UnsubscribedBefore':
        this.setQuery(Constants.ContactQueryFilters.DATE, format(e.value, dateFormat))
        break
      case 'UnsubscribedSince':
        this.setQuery(Constants.ContactQueryFilters.DATE, format(e.value, dateFormat))
        break
      default:
        this.setQuery(Constants.ContactQueryFilters.DATE, null)
        break
    }
    //To show/kept visible selected date in DatePicker
    this.setState({
      date: e.value,
    })
  }

  private onUnsubscribedReasonDropdownChange = (e) => {
    if (e.selected.key) {
        this.setQuery(Constants.ContactQueryFilters.UNSUBSCRIBED_REASON, e.selected.key ===
          Constants.ContactFilter.SELECT ? '' : e.selected.value)
        this.setState({
        selectedUnSubscribedReason: e.selected,
      })
    }
  }
}

export const SubscribersHomePage = connect(
  ({ subscriber_counts }: any) => ({ subscriberCounts: subscriber_counts.data }),
  {},
)(SubscribersHomePageComponent)

interface SubscribersHomepageState {
  contacts?: any[],
  communityId?: string,
  contactType?: string,
  isBulkUpdated?: boolean,
  isLoading: boolean,
  isSaving: boolean,
  updateType?: string,
  showArchiveContactsModal?: boolean
}

abstract class BaseSubscriberGridComponent extends Component<SubscribersProps, SubscribersHomepageState> {
  public static contextType = RasaContext

  private datasetParams: DatasetParam[]
  constructor(props: SubscribersProps, datasetParams: DatasetParam[]) {
    super(props);
    this.datasetParams = datasetParams
    this.state = {
      contacts: null,
      contactType: null,
      updateType: Constants.ARCHIVE,
      isLoading: false,
      isSaving: false,
      showArchiveContactsModal: false
    }
  }
  public componentDidMount = () => {
    const search = window.location.search
    const params = new URLSearchParams(search)
    const message = params.get('success')
    if (message) {
      this.context.store.dispatch(Flash.showFlashMessage(MESSAGE))
    }
    this.context.user.init().then(({ person, activeCommunity }) => {
      this.setState({ communityId: activeCommunity.communityId })
    })
  }
  public render() {
    return ( this.state.isLoading ? <Loading size="64"/> :
    <div>
      <div className="button-unsubscribe">
        <Button disabled={this.getContactsCount() === 0}
                  className="active-inactive-button"
                  onClick={() => this.bulkArchiveContacts()}>
                  <span className="button-text">
                    {'Archive '}
                    {this.getContactsCount()} contact(s)
                  </span>
                </Button>
                {this.getArchiveContactModal()}
      </div>
      <div>
        <RasaDataGrid<Subscriber>
          entityName="rasaSubscriber"
          datasetName="rasaSubscribers"
          editDefaultState={false}
          datasetParams={this.getDatasetparams()}
          sortable={true}
          pageable={true}
          pageSize={50}
          data={[]}
          onRowClick={(e) => this.editSubscriber(e.dataItem)}
          onDataReceived={this.dataReceived}>
          <Column field="full_name" title="Name" width="250px" />
          <Column field="email" title="Email" />
          <Column field="created" title="Created" cell={DateTimeCell} />
          <Column field="subscription_changed_date" title="Subscription Change" cell={DateTimeCell} />
          <Column cell={this.SubscriberEditCell} width={50} />
        </RasaDataGrid>
        <ResubscribeReasonModal
          isSaving={this.state.isSaving}
          onConfirm={this.handleResubscribeReasonModalConfirm}
          data={this.props.modals}
          closeModal={this.props.closeModal}
          title={"Reason for removal"}
        />
      </div>
    </div>
    )
  }

  private subscriberUrl = (s: Subscriber) => `${DashboardMenuOption.contacts}/detail?id=${s.id}`

  private editSubscriber = (s: Subscriber) => !s.is_archived && this.props.push(this.subscriberUrl(s))
  private SubscriberEditCell = (props: EditCellProps) => {
    return <EditableDropdownCell {...props}
      onEdit={this.editSubscriber}
      canUnsubscribe={true}
      canArchive={true}
      canEdit={true}
      canRestore={true}
      onToggleSubscribed={this.onToggleSubscribed} />
  }

  private onToggleSubscribed = (event: React.SyntheticEvent, data: any) => {
    this.setState({
      isLoading: true
    })
    const newIsSubscribed: boolean = !data.is_subscribed
    this.context.entityMetadata.getEntityObject('rasaSubscriber').then((entity) => {
      entity.setRecordId(this.state.communityId, data.id)
      entity.data.is_subscribed = newIsSubscribed
      entity.data.is_force_resubscribe = data.is_subscribed
      return entity.save().then((response: any) => {
        this.setState({
          isLoading: false
        })
      }).catch((err) => {
        this.setState({
          isLoading: false
        })
        if(err.response && err.response.error && err.response.error.statusCode === STATUS_CODES.UNPROCESSABLE) {
          const body: {results: [{error: string, status_code: number}]} = JSON.parse(err.response.error.body)
          if(body.results.some(x => x.error === ERROR_STATUS.MultipleHardBounce)){
            this.props.openModal(ResubscribeReasonModal.key, {
              id: data.id,
              is_subscribed: newIsSubscribed,
              is_force_resubscribe: true
            })
          }
        }
      })
    })
  }

  private handleResubscribeReasonModalConfirm = (data: { id: string }, reason: string) => {
    this.setState({
      isSaving: true,
    })
    this.context.entityMetadata.getEntityObject('rasaSubscriber').then((entity) => {
      entity.setRecordId(this.state.communityId, data.id)
      entity.data.is_subscribed = 1
      entity.data.is_force_resubscribe = true
      entity.data.resubscribe_reason = reason
      return entity.save().then(() => {
        window.location.reload()
      })
    })
  }

  private dataReceived = (data) => {
    if (data.data && data.data.length > 0) {
      this.setState({
        contacts: data.data,
        contactType: (data.data[0].is_subscribed) ? 'Subscribed' : 'Unsubscribed',
      })
      if (data.data[0].is_subscribed) {
        const counts = { subscribed: data.total }
        this.onSearch(counts)
      } else {
        const counts = { unsubscribed: data.total }
        this.onSearch(counts)
      }
    } else {
      if (this.state.contactType) {
        const counts = (this.state.contactType === 'Subscribed') ?
          { subscribed: 0 } : { unsubscribed: 0 }
        this.onSearch(counts)
      }
    }
  }

  private getDatasetparams() {
    const filterParams = Object.values(this.props).filter(
      (x: any) => x && x.param && x.value) as DatasetParam[]

    if (filterParams.length > 0) {
      return [...this.datasetParams, ...filterParams]
    } else {
      return this.datasetParams
    }
  }

  private onSearch(counts) {
    if (this.props.onSearch) {
      this.props.onSearch(counts)
    }
  }

  private bulkArchiveContacts = () => {
    this.setState({
      showArchiveContactsModal: true,
    })
  }

  private onModalClose = () => {
    this.setState({
      showArchiveContactsModal: false,
    })
  }

  private proceedBulkArchiveContacts = () => {
    if (this.state.contacts && this.state.contacts.length > 0) {
      const finalRecords = []
      this.state.contacts.forEach((record) => {
        if (record.email !== '' ) {
          finalRecords.push(updateContactStatus(record, this.state.updateType))
          record.is_archived = 1
        }
      })
      const url: string = AjaxWrapper.getServerUrl() + `/${this.state.communityId}/subscriber-upload`
      AjaxWrapper.ajax(url, HttpMethod.POST, {subscribers: finalRecords, uploadType: Constants.ARCHIVE})
      .then(() => {
        this.context.store.dispatch(
          Flash.showFlashMessage(Constants.CONTACTS_ARCHIVED_SUCCESS))
        this.setState({
          isBulkUpdated: true })
      })
      .catch(() => {
        this.context.store.dispatch(Flash.showFlashError(Constants.CONTACTS_ARCHIVED_FAILED))
      })
    }
  }

  private onArchiveContacts = () => {
    this.setState({ showArchiveContactsModal: false}, () => {
      this.proceedBulkArchiveContacts()
    })
  }

  private getArchiveContactModal(): JSX.Element {
    return (
      <div>
    <Modal isOpen={this.state.showArchiveContactsModal}>
          <ModalBody className="delete-modal">
            <div>
              <h4>Are You Sure?</h4>
              <p>{Constants.CONTACTS_ARCHIVED_WARNING_MESSAGE}</p>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.onArchiveContacts}>
              Okay
            </Button>
            <Button onClick={this.onModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    )
  }

  private getContactsCount() {
    if (this.state && this.state.contacts) {
      return this.state.contacts.filter((item) => item.is_archived === 0).length
    }
    return 0
  }
}

class SubscribedSubscribersGridComponent extends BaseSubscriberGridComponent {
  constructor(props: SubscribersProps) {
    super(props, [{ param: 'status', value: 'active' }])
  }
}

class UnsubscribedSubscribersGridComponent extends BaseSubscriberGridComponent {
  constructor(props: SubscribersProps) {
    super(props, [{ param: 'status', value: 'inactive' }])
  }
}

export const SubscribedSubscribersGrid = GenericRedux.registerNewComponentWithModals<any>(
  SubscribedSubscribersGridComponent,
  'subscribed_subscribers',
  [ResubscribeReasonModal.key]
)

export const UnsubscribedSubscribersGrid = GenericRedux.registerNewComponentWithModals<any>(
  UnsubscribedSubscribersGridComponent,
  'unsubscribed_subscribers',
  [ResubscribeReasonModal.key]
)
